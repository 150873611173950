import {
	IonPage,
	useIonToast,
	IonButton,
	IonCard,
	useIonViewDidEnter,
	IonContent,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import MainHeader from '../../components/headers/mainHeader'
import MainFooter from '../../components/mainFooter'
import { getCourse, getUserInfo } from '../../redux/actions'
import ErrorToast from '../../components/ErrorToast'
import './Course.min.css'

import { useHistory } from 'react-router'
import { LessonCardSkeleton } from '../../components/skeletons/LessonCardSkeleton'
import { clearCache, unlockTexts } from '../../services/functions'
import dayjs from 'dayjs'
import { useEffect } from 'react'

const LessonCard = ({ lesson }) => {
	const [ionToast] = useIonToast()
	const history = useHistory()
	const telephone = useSelector((state) => state.user.user.telephone)

	const subscription =
		useSelector((state) => state.user?.master_club_subscriber?.subscription) ??
		null

	let fromDayDate = null
	if (subscription?.start_date && lesson.subscription_from_day) {
		const fromDay = lesson.subscription_from_day

		const daysToAdd = fromDay > 0 ? fromDay - 1 : fromDay

		fromDayDate = dayjs(subscription.start_date)
			.add(daysToAdd, 'day')
			.format('DD/MM/YYYY')
	}

	const handleClick = (lesson) => {
		if (!telephone)
			return ionToast({
				message: 'Per interagire verifica il tuo cellulare nel tuo profilo',
				duration: 3000,
			})

		history.push(window.location.pathname + '/lezione/' + lesson.id)
	}

	const unlockModules = unlockTexts(lesson?.unlocks)
	return (
		<IonCard className="ion-padding course-lesson-module">
			<div className="title">
				Modulo {lesson.cardinality}: {lesson.group.name}
			</div>
			{unlockModules && (
				<div className="subtitle">
					<b>Ottieni accesso a:</b> {unlockModules}
				</div>
			)}
			<div className="subtitle">
				<b>Numero di video pillole da completare:</b> {lesson.user_pills.length}
			</div>
			{fromDayDate && (
				<div className="subtitle">
					<b>Disponibili da Giorno:</b> {fromDayDate}
				</div>
			)}
			{lesson.subscription_to_day && (
				<div className="subtitle">
					<b>Disponibili fino al:</b> Giorno {lesson.subscription_to_day}
				</div>
			)}

			<div className="subtitle">
				<b>Progresso attuale:</b> {lesson.completeness}%
			</div>
			<IonButton
				size="small"
				disabled={!lesson.is_enabled}
				onClick={() => handleClick(lesson)}>
				Vai alle lezioni
			</IonButton>
		</IonCard>
	)
}

const CoursePage = ({ match }) => {
	const { data, isLoading, error } = useSelector(
		(state) => state.course.getCourse
	)

	useEffect(() => {
		if (isLoading) {
			console.log('isLoading', isLoading)
		} else {
			console.log('data', data)
		}
	}, [isLoading])

	const dispatch = useDispatch()

	useIonViewDidEnter(() => {
		// dispatch(getCourse())
		console.log('dispatched getCourse()')
		if (data?.lessons_asc === undefined) {
			dispatch(getUserInfo())
		}
	})

	const LoadingRender = () => (
		<>
			{[...Array(4)].map((_, key) => (
				<LessonCardSkeleton key={key} />
			))}
		</>
	)

	return (
		<>
			<IonPage className="course-page">
				<MainHeader
					page="CoursePage"
					title={data?.group?.name || 'Percorso free'}
				/>
				<IonContent>
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					<div className="ion-padding-horizontal">
						<h3 className=" ion-no-margin ion-no-padding ion-padding-top">
							Inizia il tuo percorso con il metodo Dieta Social!
						</h3>
						<p>
							Questa settimana introduttiva ti presenta il nostro approccio
							educativo al benessere, unendo nutrizione, cucina sana, fitness e
							motivazione, per cambiare la tua visione della salute.
						</p>
						<p>
							Completa i moduli per accedere ai menù giornalieri, avvicinandoti
							ai tuoi obiettivi grazie a ricette sane e consigli utili,
							apprendendo i fondamenti della nutrizione molecolare e il loro
							impatto sulla salute. In ogni video pillola, scoprirai
							l’autorevolezza della nostra squadra di esperti, pronti a guidarti
							in ogni fase del tuo percorso. Parti dal Modulo 1!
						</p>
					</div>
					{isLoading && !error && <LoadingRender />}
					{error && <ErrorToast />}
					{!isLoading &&
						!error &&
						data?.lessons_asc?.map((lesson, key) => (
							<LessonCard
								lesson={lesson}
								key={lesson.id}
							/>
						))}
				</IonContent>
				<MainFooter />
			</IonPage>
		</>
	)
}

export default CoursePage
